import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = {
    currentUser: String,
    administratorId: String,
    administratorName: String,
    administratorEmail: String,
    organization: String,
    federation: String,
  }

  connect() {
    this.loadHeap = this.loadHeap.bind(this)
    document.addEventListener('turbo:load', this.loadHeap)
  }

  disconnect() {
    document.removeEventListener('turbo:load', this.loadHeap)
  }

  loadHeap() {
    if(this.currentUserValue) {
      heap.identify(this.currentUserValue);
    }

    heap.addUserProperties({
      administratorId: this.administratorIdValue,
      administratorName: this.administratorNameValue,
      administratorEmail: this.administratorEmailValue,
      organization: this.organizationValue,
      federation: this.federationValue,
    });
  }
}
