import { Controller } from '@hotwired/stimulus'
import { debounce } from 'underscore'

export default class extends Controller {
  static targets = ['source']
  static values = {
    timeout: { type: Number, default: 5000 }
  }

  connect() {
    this.add = debounce(this.add.bind(this), 1000)
    this.region = document.getElementById(`live-region-${this.sourceTarget.dataset.region}`)
  }

  add() {
    this.createAnnouncement()
    this.region.appendChild(this.announcement)
    setTimeout(() => this.clear(), this.timeoutValue)
  }

  createAnnouncement() {
    var announcement = this.sourceTarget.dataset.announcement.toString()
    announcement = document.createTextNode(`${announcement}`)
    var span = document.createElement('span')

    span.appendChild(announcement)
    this.announcement = span
  }

  setTimeoutValue() {
    if (this.sourceTarget.dataset.timeout) {
      this.timeoutValue = this.sourceTarget.dataset.timeout
    }
  }

  sourceTargetConnected() {
    this.setTimeoutValue()
  }

  clear() {
    this.region.removeChild(this.announcement)
  }
}
