import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [ 'source', 'success', 'primary' ]

  static values = {
    timeout: { type: Number, default: 5000 },
    content: String
  }

  copy() {
    try {
      navigator.clipboard.writeText(this.contentValue)
      this.showFeedback()
    } catch (err) {
      console.error('Clipboard only works in HTTPS. HTTPS Support is in Puma in development mode.', err)
      // logs copied value and shows ui changes for dev testing
      if (this.checkEnvironment()) {
        console.log(this.contentValue)
        this.showFeedback()
      }
    }
  }

  showFeedback() {
    this.toggleFeedback()
    setTimeout(() => this.toggleFeedback(), this.timeoutValue)
  }

  toggleFeedback() {
    this.successTarget.classList.toggle('hidden')
    this.primaryTarget.classList.toggle('hidden')
  }

  checkEnvironment() {
    return document.querySelector('.test-env-banner')
  }
}
