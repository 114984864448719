import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  copyUrl() {
    const url = window.location.href

    try {
      navigator.clipboard.writeText(url)
      document.dispatchEvent(
        new CustomEvent('toast', { detail: { message: 'Link to this view has been copied to clipboard' } })
      )
    } catch (err) {
      console.error('Clipboard only works in HTTPS. HTTPS Support is in Puma in development mode.', err)
    }
  }
}
